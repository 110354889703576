import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../config";
import axios from "axios";

export const LocationServices = {
  fetchAssignedLocations,
  addData,
  editData,
  fetchAllData,
  deleteData,
  fetchDropDownLocationsData,
  fetchLocationDataById,
  fetchBussinessCategories,
  fetchBussinessTypes,
};


function fetchDropDownLocationsData(data) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/location/list?page=${data?.currentPage}&limit=${process.env.REACT_APP_LIMIT}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchAllData() {
  return axios
    .get(`${MoNGO_DB_API_URL}/location/alllocations`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchAssignedLocations() {
  return axios
    .get(`${MoNGO_DB_API_URL}/location/assign`, HEADER_TOKEN)
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/location`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function editData(data) {
  return axios
    .put(`${MoNGO_DB_API_URL}/location/${data._id}`, { data }, HEADER_TOKEN)
    .then((response) => response);
}

function fetchLocationDataById(lid) {
  return axios
    .get(`${MoNGO_DB_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/location/${lid}`, HEADER_TOKEN)
    .then((response) => response);
}

function deleteData(lid) {
  return axios
    .delete(`${MoNGO_DB_API_URL}/location/${lid}`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchBussinessCategories() {
  return axios
    .get(`${MoNGO_DB_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/location/business-categories`, HEADER_TOKEN)
    .then((response) => response);
} 

function fetchBussinessTypes() {
  return axios
    .get(`${MoNGO_DB_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/location/business-types`, HEADER_TOKEN)
    .then((response) => response);
} 